
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup container */
  .popup {
    background-color: #f1f6ff;
    padding: 20px;
    border-radius: 16px;
    width: 35%;
    height: 70%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }

  .beta-content-container {
    flex-direction: row;
    justify-content: flex-start;
  }

  .beta-header-cont {
    width: 75%;
    margin-left: 11.5%;
    text-align: left;
    margin-top: 12.5%;
  }

  .beta-header-text {
    font-size: 2.1vw;
    font-family: 'Raleway';
    color: black;
    font-weight: 800;
  }

  .beta-desc-cont {
    width: 75%;
    margin-left: 11.5%;
    text-align: left;
    margin-top: 5%;
  }

  .beta-desc-text {
    font-size: 1.4vw;
    font-family: 'Raleway';
    color: black;
    font-weight: 400;
  }


  .beta-namecity-cont {
    width: 75%;
    margin-left: 11.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8%;
    height: 5vh;
  }

  .beta-name-form {
    width: 48%;
    text-align: left;
    height: 5vh;
    border-radius: 9px;
    font-size: 1.1vw;
    padding-left: 12px;
    background-color: #dee8fc;
    border-width: 1px;
  }

  .beta-email-cont {
    width: 75%;
    margin-left: 11.5%;
    margin-top: 3%;
    height: 5vh;
  }

  .beta-email-form {
    width: 100%;
    text-align: left;
    height: 5vh;
    border-radius: 9px;
    font-size: 1.1vw;
    padding-left: 12px;
    background-color: #dee8fc;
    border-width: 1px;
  }

  .beta-disc-cont {
    width: 75%;
    margin-left: 11.5%;
    margin-top: 1%;
  }

  .beta-disc-text {
    font-size: 0.75vw;
    font-family: 'Raleway';
    color: #7f7f7f;
    font-weight: 400;
  }

  .beta-button-cont {
    width: 30%;
    margin-left: 11.5%;
    margin-top: 2.5vh;
    height: 5.5vh;
  }

  .beta-message-cont {
    width: 75%;
    margin-left: 11.5%;
    margin-top: 2%;
  }

  .beta-message-text {
    font-size: 0.9vw;
    font-family: 'Raleway';
    color: black;
    font-weight: 400;
  }

  .beta-subheader-cont {
    width: 75%;
    margin-left: 11.5%;
    text-align: left;
    margin-top: 11%;
  }

  .beta-subheader-text {
    font-size: 2.1vw;
    font-family: 'Raleway';
    color: black;
    font-weight: 800;
  }

  .beta-subdesc-cont {
    width: 77.5%;
    margin-left: 11.5%;
    text-align: left;
    margin-top: 4%;
  }

  .beta-subdesc-text {
    font-size: 1.4vw;
    font-family: 'Raleway';
    color: black;
    font-weight: 400;
  }

  .beta-check-cont {
    width: 42.5%;
    margin-left: 28.75%;
    text-align: left;
    margin-top: 10%;
    aspect-ratio: 1;
  }

  .beta-check {
    width: 100%;
    height: 100%;
  }