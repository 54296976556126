html, body {
    margin: 0;
    padding: 0;
}

.banner {
    background-color: #F1F6FF;
    text-align: center;
    color: #021E3E;
    font-weight: 600;
    position:relative;
    padding-right: 0px;
}


/* HTML: <div class="loader"></div> */
.loaderCont{
  display: flex;
  flex-direction: column;
  justify-content: end;
padding-bottom: 3px;}

.loader {
    width: 14px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side,#000 90%,#0000) 0/calc(100%/3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 1.7s steps(4) infinite;
  }
  @keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}

  .bannerCont {
    display: flex;
    justify-content: center;
    background-color: #F1F6FF;
    padding-top: 10px;
    padding-bottom: 4px;
  }